.container {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

iframe {
  aspect-ratio: 16 / 9;
  width: 50vw;
  height: 100%;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -3px;

  @media (max-width: 1024px) {
    width: 80vw;
  }
}